@import '../../../node_modules/materialize-css/dist/css/materialize.min.css';

html {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
  height: 100%;
  color: #060605;
  width: 100%;
  overflow-x: hidden;
}

.padding-3 {
  padding: 3rem;
}

.flex {
  display: flex;
}

.justify-between {
  justify-content: space-between;
}

.btn-transparent {
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  margin: 0 1rem;
}

.btn-transparent:focus {
  background-color: transparent;
}

h1 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
}

h2 {
  font-size: 21px;
  line-height: 37px;
  font-weight: 500;
  margin-top: 0;
  position: relative;
}

h3 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}

h4 {
  font-size: 14px;
  line-height: 20px;
}

.row .col {
  padding: 0 12px;
}

#app {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
}

.background-wrapper {
  flex: 1 0 auto;
  background-image: url('../images/dch-supporter-bg.jpg') at-2x;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.green-icon {
  color: #59a387;
}

.green-text {
  color: #2cba9a;
}

.no-row-margin {
  margin-bottom: 0 !important;
}

.coral-link {
  color: #fb8f8f;

  &:hover {
    text-decoration: underline;
  }
}

.blue-link {
  color: #b1deea;

  &:hover {
    text-decoration: underline;
  }
}

.member-back-link {
  display: block;
  height: 30px;
  color: #fff;
  font-size: 14px;
  line-height: 20px;

  span {
    margin-left: 10px;
  }

  span,
  svg {
    float: left;
  }
}

.btn,
.btn-flat {
  background-color: #fb8f8f;
  text-transform: none;
  border-radius: 23px;
  font-size: 16px;
  font-weight: 500;
  box-shadow: none;
  height: 50px;
  line-height: 50px;
  width: 200px;
  text-align: center;

  &:hover,
  &:focus {
    background-color: #e17c7c;
    box-shadow: none;
  }

  &.btn-small {
    width: auto;
    height: auto;
    color: #fb8f8f;
    border: 1px solid #fb8f8f;
    background-color: #fff;
    font-size: 14px;
    line-height: 20px;
    padding: 16px;
    float: right;

    &:hover {
      color: #e17c7c;
    }
  }
}

.__react_component_tooltip {
  background-color: #ddf2fc !important;
  opacity: 1 !important;
  padding: 16px !important;
  color: #242525 !important;
  font-size: 14px;
  line-height: 20px;
  max-width: 336px;

  &.place-top::after {
    border-top-color: #ddf2fc !important;
  }

  &.place-right::after {
    border-right-color: #ddf2fc !important;
  }

  &.place-bottom::after {
    border-bottom-color: #ddf2fc !important;
  }

  &.place-left::after {
    border-left-color: #ddf2fc !important;
  }
}

.tooltip {
  margin-left: 5px;
}

.tooltip-target {
  position: relative;
  width: auto;
  padding: 0;
  color: #fb8f8f;
  height: auto;
  line-height: 100% !important;

  &:hover {
    color: #e17c7c;
  }

  &:focus {
    background-color: transparent;
  }

  svg {
    vertical-align: super;
  }
}

nav {
  margin: 2em 0;

  .brand-logo {
    height: 54px;
    color: #fff;
    fill: #fff;
    width: 221px;
  }

  li a {
    height: 64px;
    padding: 0 24px;

    &.active {
      color: #b1deea;
      border-bottom: 2px #b1deea solid;
    }
  }
}

.page-footer {
  color: #8e8e8e;
  background-color: #072534;
}

nav li.active a {
  border-bottom: 2px #fff solid;
}

.text-grey {
  color: #8e8e8e;
}

.no-data-tile {
  text-align: center;
  margin: 2em 0;

  &-content {
    color: #fffdfd;
    background-color: #0c3445;
    border-radius: 2px;
    padding: 2em;
    display: inline-block;

    p {
      margin-left: 5em !important;
    }
  }
}

.card {
  background-color: #f5fdff;
  border-radius: 7px;
  box-shadow: none;
  transition: none;
  border-width: 1px;
  border-style: solid;
  border-color: #dbdbdb;
  margin: 0 0 24px 0;

  h1 {
    margin: 0.67em 0;
  }

  h3 {
    margin: 0.67em 0;
  }

  .card-text-label {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 20px;
    display: block;
  }

  .card-text-label-sm {
    font-size: 14px;
    line-height: 20px;
    color: #8e8e8e;
    display: block;
  }

  .card-text-value {
    font-size: 24px;
    font-weight: 600;

    &-align {
      line-height: 30px;
    }
  }

  .card-content {
    border-radius: 0 0 7px 7px;
    padding: 40px;
    height: 100%;

    .card-title {
      font-size: 21px;
      line-height: 37px;
      font-weight: 500;
    }

    .meta-link {
      position: absolute;
      top: 40px;
      right: 40px;
    }

    .row {
      margin-top: 0;

      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      margin-bottom: 10px;
    }
  }

  &.full-height-card {
    height: 320px;
    overflow: hidden;

    @media (max-width: 992px) {
      height: auto;
    }
  }

  &.half-height-card {
    height: 152px;
    overflow: hidden;

    @media (max-width: 992px) {
      height: auto;
    }

    .card-content {
      padding: 24px;
    }

    &.gutter-sm {
      margin-bottom: 16px;
    }
  }

  &.info-card,
  &.coach-card {
    background-color: #2c7390;
    color: #fff;

    .card-text-label-sm {
      font-size: 14px;
      color: #ececec;
    }
  }

  &.pending-card {
    background-color: #0a71a5;
    color: #fff;

    .card-text-label-sm {
      font-size: 14px;
      color: #c0bfbf;
    }
  }

  &.revoked-card {
    background-color: #f7f7f7;
  }

  &.funding-card {
    .card-content {
      padding: 8px 24px 24px;
    }
  }

  &.account-activated-card {
    text-align: center;

    h3 {
      margin-bottom: 2em;
    }
  }

  &.success-rate-card {
    canvas {
      display: block;
      width: 100%;
      visibility: hidden;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }

    .graph-card {
      position: relative;
    }

    &-header {
      font-size: 18px;
      font-weight: 500;
    }

    .graph-toggle-label {
      font-size: 16px;
      cursor: pointer;

      &-active {
        color: #fb8f8f;
        cursor: auto;
        font-weight: bold;
      }
    }

    .line {
      fill: none;
      stroke: #fb8f8f;
      stroke-width: 4;
      stroke-linecap: round;
    }

    .grid line {
      stroke: rgba(0, 0, 0, 0.2);
      stroke-opacity: 0.7;
      stroke-width: 2;
      shape-rendering: crispEdges;
    }

    .grid path {
      stroke-width: 0;
    }

    .overlay {
      fill: none;
      pointer-events: all;
    }

    .y-axis {
      stroke-width: 0;
    }

    .y-axis > text {
      fill: rgba(0, 0, 0, 0.2);
    }

    .x-axis > g > line {
      fill: none;
      stroke: none;
    }
  }
}

body.dashboard {
  .card .card-content {
    > div,
    div > .row,
    div > .row .col:nth-child(2) {
      height: 100%;
      position: relative;
    }

    .btn {
      position: absolute;
      left: 0;
      bottom: 40px;
    }
  }
}

.text-coral {
  background-color: #fb8f8f;
}

.user-avatar {
  display: inline-block;
  float: inherit;
  width: 70px;
  height: 70px;
  margin-right: 15px;
  line-height: 70px;
  border-radius: 50%;
  background-size: cover;
  font-weight: 400;
  font-size: 24px;
  text-align: center;

  &.dark {
    color: #fff;
    background-color: #2c7390;
  }

  &.light {
    color: #2c7390;
    background-color: #fff;
  }
}

.ppb {
  position: relative;
  background-color: #d8f1fd;
  padding: 2px;
  border-radius: 7px;
  margin-top: 30px;

  .ppb-text-value {
    position: absolute;
    left: 5px;
    color: #fff;
    font-weight: 600;
    font-size: small;
    line-height: 1.1;
  }

  .ppb-meter-value {
    background-color: #2cba9a;
    height: 15px;
    border-radius: 5px;
  }
}

.rounded-circle {
  border-radius: 50%;
}

.success-tile {
  margin: 10px 0;

  &-rate {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 7px;

    @media (min-width: 993px) {
      font-size: 36px;
      line-height: 42px;
    }
  }

  &-label {
    font-size: 18px;
    line-height: 24px;
  }
}

th {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ReactTable {
  border: none;
  margin: 0 -40px -40px;
  font-size: 14px;
  line-height: 20px;

  .rt-thead {
    &.-header {
      text-transform: uppercase;
      box-shadow: none;
      border-bottom: solid 1px rgba(0, 0, 0, 0.05);
      padding: 10px 0;
    }

    .rt-tr {
      text-align: left;
      padding: 0 40px;
    }

    .rt-th {
      border-right: none;
    }
  }

  .rt-tbody {
    .rt-tr {
      padding: 0 40px;
    }

    .rt-td {
      border-right: none;
      padding: 15px 5px;
    }
  }

  .-sort-asc {
    box-shadow: none !important;

    &::before {
      content: '\25B2';
      float: right;
    }
  }

  .-sort-desc {
    box-shadow: none !important;

    &::before {
      content: '\25BC';
      float: right;
    }
  }
}

.tabs {
  background-color: #f5fdff;
  margin-bottom: 24px;

  .tab {
    text-transform: none;
  }

  .tab a {
    padding: 0;
    margin-right: 15px;
    color: #060605;

    &.active,
    &:hover {
      color: #fb8f8f;
    }
  }

  .tab a:focus,
  .tab a:focus.active {
    background-color: transparent;
  }
}

.event-icon-status {
  color: grey;

  &.assigned,
  &.acknowledged,
  &.started,
  &.recorded,
  &.pending,
  &.scheduled,
  &.cancelled,
  &.connection_lost {
    color: grey;
  }

  &.skipped,
  &.missed,
  &.failed,
  &.left {
    color: red;
  }

  &.passed,
  &.completed,
  &.attended {
    color: green;
  }

  &.invalid,
  &.pending {
    color: orange;
  }
}

.cursor-pointer {
  cursor: pointer;
}
